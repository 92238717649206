/**
 * import
 *
 * @version 0.1 (2021.06.21 : usayama)
 * @version 0.2 (2021.06.28 : usayama) // Seo 追加
 */
import React from 'react'
import { useTranslation } from 'react-i18next'
// conf, action
// components
import Seo from '@components/Tools/Seo'
// containers
import LayoutContainer from '@containers/LayoutContainer'
import LogoutContainer from '@containers/LogoutContainer'
// helper
// style, img, elm
// const

/**
 * const
 *
 * @version 0.1 (2021.06.21 : usayama)
 */
const Logout = () => {

  /** 
   * const : 
   * 
   * @version 0.1 (2021.06.28 : usayama_dx)
   */
  const { t } = useTranslation()

	/**
	 * return
	 *
	 * @version 0.1 (2021.06.21 : usayama)
	 * @version 0.2 (2021.06.28 : usayama) // Seo 追加
	 */
	return (
		<LayoutContainer>
			<Seo title={t('title.logout')} />
			<LogoutContainer />
		</LayoutContainer>
	)
}

/**
 * export
 *
 * @version 0.1 (2021.06.21 : usayama)
 */
export default Logout
