/**
 * import
 *
 * @version 0.1 (2021.06.21 : usayama)
 */
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
// conf, action
import { APP_ROUTE } from '@config/routers'
import authAction from '@redux/auth/actions'
// components
import Label from '@components/Typo/Label'
import LinkButton from '@components/UIKit/LinkButton'
// containers
// helper
import { clearToken } from '@lib/helpers'
// style, img, elm
// const
const { logout } = authAction

/** 
 * const : 
 * 
 * @version 0.1 (2021.06.21 : usayama_dx)
*/
export default function LogoutContainer(props) {

  /** 
   * const : 
   * 
   * @version 0.1 (2021.06.21 : usayama_dx)
   * @version 0.2 (2021.07.01 : usayama_dx) // state.Auth 追加
   */
  // const { ...attrs } = props
  const dispatch = useDispatch()
  const { logoutResultStatus } = useSelector(state => state.Auth)

  /** 
   * useState : 
   * 
   * @version 0.1 (2021.06.21 : usayama_dx)
   */
  const [ initPage, setInitPage ] = React.useState(false)

  /** 
   * useEffect
   * 
   * @version 0.1 (2021.07.01 : usayama)
   * @version 0.2 (2021.08.13 : usayama) // リダイレクトコメントアウト（本番でリダイレクト後 csrf エラーになるので）
   */
  React.useEffect(() => {
    if(logoutResultStatus !== null) {
      // window.location.href = '/'
    }
  }, [logoutResultStatus])

  /** 
   * componentDidMount : https://reactjs.org/docs/hooks-effect.html
   * 
   * @version 0.1 (2021.06.21 : usayama)
   */
  React.useEffect(() => {
    if(!initPage) {
      clearToken()
      dispatch(logout())
      setInitPage(true)
    }
  }, [initPage, dispatch])

  /** 
   * return : 
   * 
   * @version 0.1 (2021.06.21 : usayama_dx)
   * @version 0.2 (2021.07.01 : usayama_dx) // logoutResultStatus 追加
   */
  return (
    <div className="text-center">
    {
      logoutResultStatus && 
      <div>
        <div className="pt-5 pb-4"><Label label="text.logout" /></div>
        <LinkButton className="mb-5" color="outline-success" to={APP_ROUTE.root} label="text.to_home" />
      </div>
    }
    </div>
  )
}
